<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <v-row>
      <v-col cols="12" md="3">
        <v-card class="pa-3 white--text" color="error" flat>
          <h3>LAPORAN GRATIFIKASI</h3>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col cols="12" md="5">
        <v-card class="px-3 pt-3">
          <span class="font-weight-bold">PENGIRIM</span>
          <v-text-field class="mt-5" label="Nama" outlined></v-text-field>
          <v-textarea class="mt-2" label="Alamat" outlined></v-textarea>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-img src="@/assets/gambar/panduan-blanko.png"></v-img>
      </v-col>
    </v-row>

    <v-row class="mb-5">
      <v-col cols="12" md="6">
        <v-card class="px-3 pt-3">
          <span class="font-weight-bold">IDENTITAS PELAPOR</span>
          <v-text-field
            class="mt-5"
            label="Nama Lengkap"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Nomor KTP"
            placeholder="NIK"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Tempat & Tanggal Lahir"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Jabatan / Pangkat / Golongan"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Nama Instansi"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Unit"
            placeholder="Unit Eselon/I/II/II/IV/Unit Kerja"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Email"
            append-icon="mdi-email"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Nomor Telpon"
            append-icon="mdi-phone"
            outlined
          ></v-text-field>
          <v-textarea
            class="mt-2"
            label="Alamat"
            placeholder="Mohon diisi alamat lengkap anda besrta nama Kabupaten/Kota, Kecamatan, dan Kelurahan/Desa"
            outlined
          ></v-textarea>
          <v-textarea
            class="mt-2"
            label="Alamat Kantor"
            placeholder="Mohon diisi alamat lengkap besrta nama Kabupaten/Kota, Kecamatan, dan Kelurahan/Desa"
            outlined
          ></v-textarea>
          <v-radio-group row class="mt-n3">
            <span class="mr-5">Alamat Pengirim Surat:</span>
            <v-radio label="Rumah" value="rumah"></v-radio>
            <v-radio label="Kantor" value="kantor"></v-radio>
          </v-radio-group>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="px-3 pt-3 mb-5">
          <span class="font-weight-bold">DATA PENERIMA GRATIFIKASI</span>
          <v-textarea
            class="mt-5"
            label="1) Jenis Penerimaan Dan Uraian"
            outlined
          ></v-textarea>
          <v-text-field
            class="mt-2"
            label="2) Harga/Nilai Nominal/Taksiran"
            prefix="Rp."
            outlined
          ></v-text-field>
          <v-textarea
            class="mt-2"
            label="3) Peristiwa Penerimaan"
            outlined
          ></v-textarea>
          <v-textarea
            class="mt-2"
            label="4) Tempat Dan Tanggal Penerimaan"
            outlined
          ></v-textarea>
        </v-card>

        <v-card class="px-3 pt-3">
          <span class="font-weight-bold">DATA PEMBERI GRATIFIKASI</span>
          <v-text-field class="mt-5" label="5) Nama" outlined></v-text-field>
          <v-text-field
            class="mt-2"
            label="Pekerjaan dan Jabatan"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="Alamat / Telpon / Fax / Email"
            outlined
          ></v-text-field>
          <v-text-field
            class="mt-2"
            label="6) Hubungan Dengan Pemberi"
            outlined
          ></v-text-field>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="px-3 pt-3">
          <span class="font-weight-bold">ALASAN DAN KRONOLOGI</span>
          <v-textarea
            class="mt-5"
            label="7) Alasan Pemberian"
            outlined
          ></v-textarea>
          <v-textarea
            class="mt-2"
            label="8) Kronologi Penerimaan"
            outlined
          ></v-textarea>
          <v-textarea
            class="mt-2"
            label="Alamat / Telpon / Fax / Email"
            outlined
          ></v-textarea>
          <v-file-input
            class="mt-2"
            label="9) Dokumen Yang Dilampirkan"
            append-icon="mdi-paperclip"
            outlined
            prepend-icon
          >
          </v-file-input>
          <v-textarea
            class="mt-2"
            label="10) Catatan Tambahan"
            outlined
          ></v-textarea>
        </v-card>
      </v-col>
    </v-row>

    <div class="my-5 text-right">
      <v-btn color="primary"> Kirim </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Gratifikasi",
        disabled: true,
        href: "",
      },
      {
        text: "Blanko",
        disabled: false,
        href: "/gratifikasi/blanko",
      },
    ],
  }),
};
</script>